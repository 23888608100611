import React, {useState} from "react";
import * as styles from "src/styles/Ems.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import ROICalculator from "src/components/blocks/ROICalculator";
import Modal from 'react-modal';
import Dot from "../../../static/staticPages/Dot.svg"
import emsHeroBanner from "../../../static/staticPages/ems-hero.jpg"
import featureProduct2 from "../../../static/staticPages/featureProduct2.svg"
import Play from "../../../static/staticPages/Play.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import resource1 from "../../../static/staticPages/resource_1_ems.jpg"
import resource2 from "../../../static/staticPages/resource_2_ems.jpg"
import resource3 from "../../../static/staticPages/resource_3_ems.jpg"
import resource4 from "../../../static/staticPages/resource_4_ems.jpeg"
import resource5 from "../../../static/staticPages/resource_5_ems.jpeg"
import feature1 from "../../../static/staticPages/EMS-Centralized-Scheduling.png"
import feature2 from "../../../static/staticPages/EMS-Optimize-Your-Resources.png"
import feature3 from "../../../static/staticPages/EMS-Improve-Communication.png"
import feature4 from "../../../static/staticPages/EMS-Enhance-User-Experience.png"
import feature5 from "../../../static/staticPages/EMS-Utilize-Data-Driven-Insights.png"
import feature6 from "../../../static/staticPages/EMS-Integrate-With-All-Your-Systems.png"
import benefit1 from "../../../static/staticPages/benefit1.svg"
import benefit2 from "../../../static/staticPages/benefit2.svg"
import benefit3 from "../../../static/staticPages/benefit3.svg"
import benefit4 from "../../../static/staticPages/benefit4.svg"
import benefit5 from "../../../static/staticPages/benefit5.svg"
import benefit6 from "../../../static/staticPages/benefit6.svg"
import tick from "../../../static/staticPages/tick.png"
import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import fullWidthBannerEms1 from "../../../static/staticPages/fullWidthBannerEms1.png"
import logo1 from "../../../static/staticPages/logo1.svg"
import logo2 from "../../../static/staticPages/logo2.svg"
import logo3 from "../../../static/staticPages/logo3.svg"
import logo4 from "../../../static/staticPages/logo4.svg"
import logo5 from "../../../static/staticPages/logo5.svg"
import logo6 from "../../../static/staticPages/logo6.svg"
import babsoncollegeLogo from "../../../static/staticPages/babsoncollege-logo.png"
import banfieldLogo from "../../../static/staticPages/Banfield-logo.png"
import cookMedicalLogo from "../../../static/staticPages/cook_medical.png"
import lorealLogo from "../../../static/staticPages/loreal_logo.png"
import OrlandoLogo from "../../../static/staticPages/Mc-orlando-logo_1.png"
import calendaruserssolid from "../../../static/staticPages/calendar-users-solid.svg"
import calendardaysolid from "../../../static/staticPages/calendar-day-solid.svg"
import calendarweeksolid from "../../../static/staticPages/calendar-week-solid.svg"
import gallerythumbnailssolid from "../../../static/staticPages/gallery-thumbnails-solid.svg"
import mobilesolid from "../../../static/staticPages/mobile-solid.svg"
import filechartsolid from "../../../static/staticPages/file-chart-column-solid.svg"
import screenuserssolid from "../../../static/staticPages/screen-users-solid.svg"
import featureLogo from "../../../static/staticPages/featureLogo.svg"
import emsHeroBanner1 from "../../../static/staticPages/emsHeroBanner1.jpg"
import arrowRight from "../../../static/staticPages/arrowRight.svg"
import design1 from "../../../static/staticPages/design1.png"
import design2 from "../../../static/staticPages/design2.svg"
import design3 from "../../../static/staticPages/design3.svg"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"
import logoStrip1 from "../../../static/staticPages/emsLogoStrip1.svg"
import logoStrip2 from "../../../static/staticPages/emsLogoStrip2.png"
import logoStrip3 from "../../../static/staticPages/emsLogoStrip3.png"
import redeyeLogo from "../../../static/staticPages/redeye_logo.png"
import service1 from "../../../static/staticPages/ems-logo.svg"
import service2 from "../../../static/staticPages/lucernex-logo.png"
import service3 from "../../../static/staticPages/service3.svg"
import service4 from "../../../static/staticPages/service4.svg"
import mcLogo from "../../../static/staticPages/mc-logo.png"
import dukeLogo from "../../../static/staticPages/drb-icons/customers/Duke_logo.png";
import minnesotta from "../../../static/staticPages/drb-icons/customers/Minnesota-State-logo.png";
import essex from "../../../static/staticPages/drb-icons/customers/ecc_logo.png";
import notre from "../../../static/staticPages/drb-icons/customers/university_notre_dame_logo_customer_quote.png";
import babson from "../../../static/staticPages/drb-icons/customers/babsoncollege-logo.png";
import dukeLogoBlack from "../../../static/staticPages/Duke-blacklogo.png";
import minnesottaBlack from "../../../static/staticPages/Minnesota-State-blacklogo.png";
import essexBlack from "../../../static/staticPages/ecc-blacklogo.png";
import notreBlack from "../../../static/staticPages/university_notre_dame-blacklogo.png";
import babsonBlack from "../../../static/staticPages/babson-blacklogo.png";
import { useHeroImages } from "src/hooks/useHeroImages";
import { useFormImages } from "src/hooks/useFormImages";
import { getPageDataJsonPath } from "src/utils";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";

const data = {
  ROICalculator: {
    "type": {
      "id": "roi_calculator"
    },
    "uuid": "78184b28-8a91-47bf-8b65-be5c530fc2c7",
    "nid": 14499,
    // "title": "ROI Calculator",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldRoiCalculatorId": "dl_calculator",
    "fieldEmbedCode": '<script src="https://jamaica.value-cloud.com/embed.js" width="100%" height="100%" calc="1a99b2a451a862022d171c9577db445c" currency_code="USD" lang="en_US" company="company_name"></script>'
  },
  heroBanner:{
    title: 'EMS: Desk, Room and Event Scheduling Made Easy',
    description:'Experience the future of space management with our advanced room and resource scheduling software. From desk booking to event scheduling, our integrated solutions ensure smooth operations and optimal space utilization for any organization.',
    link1: {
      url: '/request-demo',
      text: 'Request a demo'
    },
    link2: {
      url: '/demo',
      text: 'Interactive demo'
    },
    image: {
      url: emsHeroBanner,
      altText: 'Employees using room booking software to reserve an office for a meeting, ensuring efficient space utilization'
    },
    logo: {
      url: service1,
      altText: "Accruent EMS Logo"
    }
  },
  logoStrip:[
    {
      url: logoStrip1,
      altText: 'EMS Capterra rating 4.3 stars indicating high user satisfaction'
    },
    {
      url: logoStrip2,
      altText:'EMS Software Advice rating 4.3 stars indicating high user satisfaction'
    },
    {
      url: logoStrip3,
     altText:'EMS GetApp rating 4.3 stars indicating high user satisfaction'
    }
  ],
  benefits: {
    title: 'Simplify Your Workplace & Campus Management Once and For All',
    description: "Say goodbye to overcomplicated space, classroom, and event scheduling.",
    items: [
      {
        logoUrl: feature1,
        altText: "Dashboard report in EMS Software showcasing centralized scheduling",
        title: "Centralize Scheduling",
        description: "Streamline scheduling for meeting rooms, events, shared spaces, desks, exams, and more to reduce administrative overhead and save time.",
        link:{
          text:'Explore regulatory compliance',
          url:'/'
        }
      },
      {
        logoUrl: feature2,
        altText: "Resource dashboard report in EMS highlighting resource optimization",
        title: "Optimize Your Resources",
        description: "Maximize the use of available space and resources, ensuring that you get the most out of your square footage and create delightful experiences.",
        link:{
          text:'Explore asset availability',
          url:'/'
        }
      },
      {
        logoUrl: feature3,
        altText: "Communication functionality in EMS Software showing improved team communication",
        title: "Improve Communication",
        description: "Facilitate better communication between departments, staff, and external stakeholders through integrated communication tools like calendar scheduling, video conferencing, and multi-device scheduling.",
        link:{
          text:'Explore quality control',
          url:'/'
        }
      },
      {
        logoUrl: feature4,
        altText: "Users easily booking spaces and resources in EMS for enhanced experience",
        title: "Enhance User Experience",
        description: "Use powerful analytics and data to make space decisions that optimize space utilization and increase employee satisfaction.",
        link:{
          text:'Explore operation visibility',
          url:'/'
        }
      },
      {
        logoUrl: feature5,
        altText: "Generated reports and analytics in EMS providing data-driven insights",
        title: "Utilize Data-Driven Insights" ,
        description: "Generate reports and analytics that help your teams understand space utilization and make informed facility and space management decisions.",
        link:{
          text:'Explore operation visibility',
          url:'/'
        }
      },
      {
        logoUrl: feature6,
        altText: "Example of integrating with other systems in EMS dashboard",
        title: "Integrate With All Your Systems",
        description: "Integrate with in-office and on-campus systems such as HVAC, lighting controls, and AV equipment for real-time insights and automated room readiness.",
        link:{
          text:'Explore inventory management',
          url:'/'
        }
      }
    ],
    info:{
      title: "Trusted by industry leaders across 75+ countries",
      images:[
        {
          url: dukeLogoBlack,
          altText:'Duke University Logo'
        },
        {
          url: minnesottaBlack,
          altText:'Minnesota State College and Universities logo'
        },
        {
          url: essexBlack,
          altText:'Essex County Council logo'
        },
        {
          url: notreBlack,
          altText:'University of Notre Dame logo'
        },
        {
          url: babsonBlack,
          altText:'Babson College logo'
        }
    ],
    link: {
      text:"Request a demo",
      url:'/request-demo'
    }
    }
  },
  threeColTwoRowComp: {
    title:"Explore EMS' Powerful Capabilities",
    description: "Why settle for a one-size-fits-all solution? EMS solves for a multitude of scenarios and is designed to meet your organization's wide range of scheduling and space management needs",
    items:[
      {
        logoUrl: screenuserssolid,
        altText: "Screen icon highlighting the solution",
        title:"Classroom and Exam Scheduling",
        description: "Eliminate complexities and redundancies to experience a smooth and efficient classroom scheduling process."
      },
      {
        logoUrl: calendaruserssolid,
        altText: "Calendar icon highlighting the solution",
        title:"Conference and Event Management",
        description: "Simplify event management processes with flexible, fully integrated event management software."
      },
      {
        logoUrl: calendardaysolid,
        altText: "Calendar days icon highlighting the solution",
        title:"Meeting and Room Scheduling",
        description: "Make it easy for people to find their ideal meeting place while creating a flexible, activity-based space.",
      },
      {
        logoUrl: gallerythumbnailssolid,
        altText: "Gallery icon highlighting the solution",
        title:"Modern Digital Signage and Kiosks",
        description: "Take your room scheduling software to the next level with real-time meeting information and immediate desk or room availability details.",
      }
    ],
    // link:{
    //   text: 'Explore the many benefits Accruent software offers',
    //   url: '/'
    // }
  },
  fullWidthBanner:{
    image: {
      url: babsoncollegeLogo,
      altText:"Babson Colleges Logo"
    },
    title:"Director of Campus and Community Events, Babson College",
    description: "“[EMS] Has endless opportunities… I’ve heard in some sessions where someone would say ‘I wish it could do that’ and a few of us would say ‘It does do that!’”",
    name: "Therese Doyle",
    position: "Director of Campus and Community Events",
    // companyName: " Babson College"
  },
  logoSlider:{
    logoList:[
      {
        url: dukeLogo,
        altText:'Duke University Logo'
      },
      {
        url: minnesotta,
        altText:'Minnesota State College and Universities logo'
      },
      {
        url: essex,
        altText:'Essex County Council logo'
      },
      {
        url: notre,
        altText:'University of Notre Dame logo'
      },
      {
        url: babson,
        altText:'Babson College logo'
      }
    ]
  },
  features: {
    title: 'Stay Ahead with Industry-Leading Capabilities',
    description: "Check out how EMS' powerful features can help your team eliminate space and resource scheduling headaches.",
    logo:{
      url: service1,
      altText: 'Accruent EMS Logo'
    },
    items: [
      {
        logoUrl: calendarweeksolid,
        altText: "Calendar Icon representing the feature",
        title: "Seamless Integrations",
        description: "Integrate to your favorite hardware and software systems, including Outlook, Zoom, in-room hardware, and third-party signage.",
        // link:{
        //   text:'Explore room booking',
        //   url:'/'
        // }
      },
      {
        logoUrl: mobilesolid,
        altText: "Mobile Icon representing the feature",
        title: "Flexible Access Points",
        description: "Access and change reservations anywhere you want via the Direct Spaces mobile app, calendar add-ons, kiosks, web, and room signs.",
        // link:{
        //   text:'Explore mobile app',
        //   url:'/'
        // }
      },
      {
        logoUrl: filechartsolid,
        altText: "File chart Icon representing the feature",
        title: "Custom Reports",
        description: "Choose from 100+ standard reports or build and share customized reports using the query builder.",
        // link:{
        //   text:'Explore custom reports',
        //   url:'/'
        // }
      }
    ]
  },
  heroBanner1:{
    title: 'Look under the hood',
    description:'Learn more about our software, get your questions answered, and find out how it can complement your current technology and processes.',
    link1: {
      url: '/request-demo',
      text: 'Request a demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: emsHeroBanner1,
      alt: 'Data engineer using software to enhance technology and processes'
    }
  },
  LinkStrip: {
    url:'/',
    text:'Calculate what impact EMS will have on your business'
  },
  services: {
    title: "Integrate seamlessly with your systems",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    items:[
      {
        logo:{
          url: service2,
          alt: "Alt"
        },
        title: "Real Estate Management Software",
        description:"Market-leading real estate software solutions manage lease, site planning and construction complexities.",
        link:{
          url: '/products/lucernex',
          text: "Explore Lucernex"
        }
      },
      {
        logo:{
          url: service3,
          alt: "Alt"
        },
        title: "Engineering Document Management Made Easy",
        description:"Engineering document management system streamlines compliance and maximizes compay-wide collaboration.",
        link:{
          url: '/products/redeye',
          text: "Explore RedEye"
        }
      },
      {
        logo:{
          url: service4,
          alt: "Alt"
        },
        title: "Multi-site, multi-industry CMMS software",
        description:"CMMS software reduces downtime, predicts maintenance needs and managers asset and equipment inventory.",
        link:{
          url: '/products/maintenance-connection',
          text: "Explore Maintenance Connection"
        }
      }
    ]
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url: resource1,
          altText: 'Image of a representative from Minnesota State College discussing EMS success.'
        },
        title:"How Minnesota State saved $50M with EMS ",
        description:"Learn how Minnesota State saved $50M and eliminated 60,000 square feet of wasted space with EMS.",
        link: 'https://www.emssoftware.com/resources/case-studies/minnesota-state-colleges-and-universities',
        button:{
          text: 'Case Study',
        }
      },
      resource2: {
        image:{
          url:resource2,
          altText: 'Team meeting in an office, scheduled by EMS software.'
        },
        title:"EMS: Scheduling for the Modern Campus",
        description:"Get the functionality your academic and event schedulers demand ― while increasing overall space management efficiency.",
        link: 'https://www.emssoftware.com/resources/brochures/ems-scheduling-modern-campus',
        button:{
          text: 'Brochure',
        }
      },
      list:[
        {
          image:{
            url:resource3,
            altText: 'An image of a finger selecting an app on a tablet.'
          },
          title:"Feature Comparison: EMS Web App & EMS for Microsoft Outlook",
          link: 'https://www.emssoftware.com/resources/brochures/feature-comparison-ems-web-app-ems-microsoft-outlook',
          button:{
            text: 'Brochure',
          }
        },
        {
          image:{
            url:resource4,
            altText: 'An aerial image of a university campus.'
          },
          title:"Texas A&M University",
          link: 'https://www.emssoftware.com/resources/case-studies/texas-university',
          button:{
            text: 'Case Study',
          }
        },
        {
          image:{
            url:resource5,
            altText: 'Employee working on her computer in the office.'
          },
          title:"Maximizing Efficiency: The Role of Data in Space Planning and Management",
          link: '/resources/blog-posts/maximizing-efficiency-role-of-data-space-planning-and-management',
          button:{
            text: 'Blog',
          }
        }
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url:Play1,
            altText: 'Alt text'
          },
          title:"EMS: Meeting & Room Scheduling",
          description: "Eliminate complexities and redundancies to experience a smooth and efficient meeting and room scheduling process.",
          link: "https://accruent.wistia.com/medias/hgbgp4tj44",
          wistiaId: 'hgbgp4tj44'
        },
        {
          logo:{
            url:Play1,
            altText: 'Alt text'
          },
          title:"EMS: Room Booking",
          description: "See how to book a meeting with a colleague or classmate easily and effectively using the EMS web app room scheduler.",
          link: "https://accruent.wistia.com/medias/ueulj7k0wt",
          wistiaId: 'ueulj7k0wt'
        },
        {
          logo:{
            url:Play1,
            altText: 'Alt text'
          },
          title:"EMS: Direct Spaces Mobile App",
          description: "Get comprehensive meeting and room booking functionality on-the-go within the Direct Spaces mobile app.",
          link: "https://accruent.wistia.com/medias/2sai9j573c",
          wistiaId: '2sai9j573c'
        },
        {
          logo:{
            url:Play1,
            altText: 'Alt text'
          },
          title:"EMS: Custom Reports",
          description: "Choose from 100+ standard reports or build and share customized reports using the query builder.",
          link: "https://accruent.wistia.com/medias/94oms53nx8",
          wistiaId: '94oms53nx8'
        }
      ]
    }
  },
  support:{
    logo:{
      url: service1,
      altText: 'Accruent EMS Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'How can this EMS scheduling software be used in universities?',
        descList: [
          {
            text: '<span>EMS scheduling software connects campuses with ease. Automate exam schedules, set recurring meetings, and receive up-to-the-minute alerts to never miss a thing.</span>'
          },
          {
            text: '<span>Whether the school is 100% in-person, remote, or a hybrid of the two, EMS scheduling software provides a much-needed connection across teams.</span>'
          },
          {
            text: '<span>The manner in which <a href="https://www.emssoftware.com/industries/higher-education" style="text-decoration-line:underline">colleges communicate</a> is evolving. You need EMS scheduling software that evolves with you.</span>'
          }
        ]
      },
      {
        title: 'Does EMS scheduling software work on mobile devices?',
        descList: [
          {
            text: '<span>Yes, Accruent’s EMS scheduling software functions across mobile devices, giving your team the ability to create and update schedules on the go.</span>'
          },
          {
            text: '<span>Create reservations, check people in, and reserve seats straight from the <a href="/resources/blog-posts/improve-efficiency-mobile-access-your-engineering-document-management-system" style="text-decoration-line:underline">mobile interface</a>. Collaborate with the entire team and share updates along the way.</span>'
          },
          {
            text: '<span>Mobile scheduling makes all processes accessible across your organization.</span>'
          }
        ]
      },
      {
        title: 'How does EMS software help with event planning?',
        descList: [
          {
            text: '<span>This EMS software helps conference and <a href="/resources/blog-posts/streamline-events-ems" style="text-decoration-line:underline">event planners</a> handle the logistics process more easily.</span>'
          },
          {
            text: '<span>Assign roles and allocate resources across your event with the click of a button. This includes making scheduling changes/updates and coordinating attendee reservations.</span>'
          },
          {
            text: '<span>Streamline and automate your entire event.</span>'
          }
        ]
      },
      {
        title: 'Is this EMS software customizable for all organizations?',
        descList: [
          {
            text: '<span>Yes, whether you are a business, university, or are putting on a conference, this software can be tailored to suit the needs of your organization.</span>'
          },
          {
            text: '<span>Choose from 100+ customizable reports to add an extra level of specificity to any project. Easily build your own based on the metrics you want to track most.</span>'
          },
          {
            text: '<span>Compile the right information for the right people.</span>'
          }
        ]
      },

    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
  design:{
    title:"Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    link: {
      url:'/products',
      text:'Explore all Accruent products'
    },
    productList:[
      {
        url: design1,
        altText: 'Accruent EMS Logo',
        link:'/products/ems'
      },
      {
        url:redeyeLogo,
        altText: 'Accruent Redeye Logo',
        link:'/products/redeye'
      },
      {
        url: mcLogo,
        altText: 'Accruent Maintenance Connection Logo',
        link:'/products/maintenance-connection'
      },
      {
        url: service2,
        altText: 'Accruent Lucernex Logo',
        link:'/products/lucernex'
      }
    ]
  }
}

const HeroBanner = (heroImages) => {
  return (<>
     <div className={styles.heroContainer}>
        <div className={styles.leftWrapper}>
          <div className={styles.heroLogoTitleWrapper}>
            <img src={data?.heroBanner?.logo?.url} alt={data?.heroBanner?.logo?.altText} className={styles.heroLogo}></img>
            <h1 className={styles.heroTitle}>
              {data?.heroBanner?.title}
            </h1>
          </div>
          <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Logo}><img src={Play}></img></span>
              <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
            </a>
        </div>
        </div>
        <div>
          {/* <img className={styles.heroImage} src={data?.heroBanner?.image?.url} alt={data?.heroBanner?.image?.altText}></img> */}
          <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/ems-hero.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
        </div>
     </div>
  </>)
}

const LogoStrip = () => {
  return (
    <div className={styles.LogoStripContainer}>
      {
        data?.logoStrip?.map((img, index)=>(
          <img key={index} src={img?.url} altText={img?.altText}></img>
        ))
      }
    </div>
  )
}

const Benefits = () => {
  return (
    <div className={styles.benefitContainer}>
      <div className={styles.benefitGridCompWrapper}>
        <div className={styles.benefitHeadingWrapper}>
          <h2 className={styles.benefitTitle}>{data?.benefits?.title}</h2>
          <p className={styles.benefitDesc}>{data?.benefits?.description}</p>
        </div>
        <div className={styles.benefitGridWrapper}>
          {
            data?.benefits?.items?.map((item,index)=>
              <div key={index} className={styles.benefitBox}>
                <div className={styles.benefitWrapper}>
                  <div className={styles.benefitLogoWrapper}>
                    <img className={styles.benefitCardImg} src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                  </div>
                </div>
                <div className={styles.benefitCardTextWrapper}>
                  <h3 className={styles.benefitGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.benefitGridDesc}>{item?.description}</p>
                </div>
              </div>
            )      
          }
        </div>       
      </div>
      <div className={styles.benefitProductsWrapper}>
        <p className={styles.benefitProductsText}>{data?.benefits?.info?.title}</p>
        <div className={styles.benefitProductsLogoList}>
          {
            data?.benefits?.info?.images?.map((image,index)=>
              <div key={index} className={styles.benefitProductsLogo}>
                <img src={image?.url} alt={image?.altText}></img>
              </div>
            )
          }
        </div>
        <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.benefits?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
            </a>
      </div>
    </div>
  )
}

const ThreeColComp = () => {
  return (
    <div className={styles.threeColContainer}>
      <div className={styles.threeColHeadWrapper}>
        <h2 className={styles.threeColTitle}>{data?.threeColTwoRowComp?.title}</h2>
        <p className={styles.threeColDesc}>{data?.threeColTwoRowComp?.description}</p>
      </div>
      <div className={styles.threeColGridWrapper}>
        {
          data?.threeColTwoRowComp?.items?.map((item, index)=>
            <div key={index} className={styles.threeColGridBox}>
              <div className={styles.threeColGrid}>
                <div className={styles.threeColLogoWrapper}>
                  <img src={item?.logoUrl} alt={item?.altText}></img>
                </div>
                <h3 className={styles.threeColGridTitle}>{item?.title}</h3>
                <p className={styles.threeColGridDesc}>{item?.description}</p>
              </div>
            </div>
          )
        }
      </div>
      {data?.threeColTwoRowComp?.link ? 
        (
          <a href={data?.threeColTwoRowComp?.link?.url} className={styles.threColLinkWrapper}>
            <span className={styles.threeColLinkText}>{data?.threeColTwoRowComp?.link?.text}</span>
            <img src={arrowRightBlue} alt='Right arrow'></img>
          </a>
      ) : ""}
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText}></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position}</p>
            {/* <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position},{ data?.fullWidthBanner?.companyName}</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage} src={logo?.url} alt={logo?.altText}></img>
      )
      }
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <img src={data?.features?.logo?.url} alt={data?.features?.logo?.altText} className={styles.heroLogo}></img>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
              <div className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText}></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
              {item?.link ? 
              (
                    <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                      <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                      <img src={arrowRightBlue} alt='Right arrow'></img>
                    </a>
              ) : ""}
              </div>
            )
          }
        </div>       
      </div>
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.hero1leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.hero1BtnWrapper}>
            <a href={'#mktoForm'} className={styles.hero1Btn1Wrapper}>
              <span className={styles.hero1Btn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.hero1Btn1}>
                <img className={styles.hero1Btn1Img} src={Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.hero1Btn2Wrapper}>
              <span className={styles.hero1Btn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src={arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.hero1Image} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const LinkStrip = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div className={styles.LinkStripContainer} onClick={() => openModal()}>
        {/* <a href={data?.LinkStrip?.url} className={styles.LinkStripWrapper}> */}
        <div className={styles.LinkStripWrapper}>
          <span className={styles.LinkStripText}>{data?.LinkStrip?.text}</span>
          <img src={arrowRight} alt='Right arrow'></img>
        </div>
        {/* </a> */}
      </div>
      <ROICalculatorModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
    </>
  )
}

const Services = () => {
  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceHeadWrapper}>
        <h2 className={styles.serviceTitle}>{data?.services?.title}</h2>
        <p className={styles.serviceDesc}>{data?.services?.description}</p>
      </div>
      <div className={styles.serviceCardContainer}>
        {
          data?.services?.items?.map((item,index)=>
            <div key={index} className={styles.serviceCardWrapper}>
            <div className={styles.serviceCardTextWrapper}>
              <div className={styles.serviceCardLogoWrapper}>
                <img className={styles.serviceCardLogo} src={item?.logo?.url} alt={item?.logo?.alt}></img>
              </div>
              <div className={styles.serviceCardText}>
                <h3 className={styles.serviceCardTitle}>{item?.title}</h3>
                <p className={styles.serviceCardDesc}>{item?.description}</p>
              </div>
            </div>
            <a href={item?.link?.url} className={styles.serviceCardLinkWrapper}>
              <span className={styles.serviceCardLinkText}>{item?.link?.text}</span>
              <img src={arrowRightBlue} alt='Right arrow'></img>
            </a>
          </div>
          )
        }
      </div>
    </div>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={`${styles.resourceCard} ${styles.resourceListCardBig}`}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={`${styles.resourceCard} ${styles.resourceListCardBig}`}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index)=>
                <a key={index} href={item?.link} className={styles.resourceMultiCard}>
                  <div>
                    <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                        <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                  <div className={styles.resourceMultiCardTextWrapper}>
                    <p className={styles.resourceMultiCardText}>{item?.title}</p>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h2 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h2>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}></p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img>
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow right'></img>
        </a>
      </div>
    </div>
  )
}

const Design = () => {
  return (
    <div className={styles.designContainer}>
      <div className={styles.designTextWrapper}>
        <div className={styles.designTitleWrapper}>
          <h2 className={styles.designTitle}>{data?.design?.title}</h2>
          <div className={styles.designDescWraper}>
            <p className={styles.designDesc}>{data?.design?.description}</p>
            <a href={data?.design?.link?.url} className={styles.designLink}>
              <span className={styles.designLinkText}>{data?.design?.link?.text}</span>
              <img src={arrowRightBlue} alt='arrow img'></img>
            </a>
          </div>
        </div>

      </div>
      <div className={styles.designLogoContainer}>
        <div className={styles.designLogoStrip}>
          {
            data?.design?.productList?.map((item, index)=>
              <a href={item?.link} key={index} className={styles.designLogoWrapper}>
                <img className={styles.designLogo} src={item?.url} alt={item?.altText}></img>
              </a>
            )
          }
        </div>
      </div>
    </div>
  )
}

const customStylesROI = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStylesROI = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const ROICalculatorModal = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStylesROI} className={''}>
    <div style={{height:'560px', width:'1024px'}}> 
      <button onClick={onRequestClose} style={closeButtonStylesROI}>x</button>
      {/* <div> */}
        <ROICalculator
              data={data.ROICalculator}
        />
      {/* </div> */}
    </div>
  </Modal>
);

export default function Ems() {
  const heroImages = useHeroImages();
  const formImages = useFormImages()
  const pageDataPath = getPageDataJsonPath('/products/ems')

  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
        "alt": "Form Image"
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
  return (
    <>
      <SEO
        meta={
          [
            { 'name': 'title', 'content': 'EMS Workplace Management Software | Accruent' },
            { 'name': 'description', 'content': "Streamline workspace, event, and resource scheduling with Accruent's EMS software for a safe, enriching, and modern experience in workplaces and campuses." }
          ]
        }
        heroImage={heroImages['staticPages/ems-hero.jpg']}
        preloads= {[pageDataPath]}
        lang="en"
        pageUrl="/products/ems"
        alternatives={[
          {
              "href": "/products/ems",
              "hreflang": "en"
          },
          {
              "href": "/uk/products/ems",
              "hreflang": "en-gb"
          },
          {
              "href": "/products/ems",
              "hreflang": "x-default"
          }
      ]}  
      />
      <HeroBanner heroImages={heroImages}/>
      <LogoStrip/>
      <Benefits/>
      <ThreeColComp/>
      <FullWidthBanner/>
      <LogoSlider/>
      <Features/>
      <HeroBanner1/>
      <LinkStrip/>
      {/* <Services/> */}
      <Resource/>
      <Support/>
      <div id="mktoForm">
      <ContentMarketoForm
        entity={contentEntity}
        locale="en"
        pageTitle="EMS: Desk, Room and Event Scheduling Made Easy"
        formFullWidth={false}
      />
      </div>
      <Design/>
    </>
  );
}